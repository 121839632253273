import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const TenantPersonalIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      className={classes.svg}
      viewBox="2 2 16 16"
      role="presentation"
      focusable="false"
    >
      <g className={cx(iconClassNames.outline, classes.outlinePart)}>
        <path d="M8.9975 2.38854C9.56767 1.87626 10.4323 1.87627 11.0025 2.38854L16.5025 7.33008C16.8191 7.61457 17 8.0202 17 8.44587V8.76406C16.8468 8.62693 16.6796 8.5054 16.5007 8.40196C16.3099 8.29157 16.1058 8.20161 15.8916 8.135C15.8743 8.11323 15.8551 8.09279 15.8342 8.07394L10.3342 3.1324C10.1441 2.96164 9.85589 2.96164 9.66583 3.1324L4.16583 8.07394C4.06029 8.16877 4 8.30398 4 8.44587V15.5C4 15.7761 4.22386 16 4.5 16H7C7.27614 16 7.5 15.7761 7.5 15.5V12C7.5 11.1716 8.17157 10.5 9 10.5H11C11.3852 10.5 11.7366 10.6452 12.0022 10.8839C12.0007 10.9224 12 10.9611 12 11C12 11.6134 12.1841 12.1837 12.5 12.6589V13C12.1445 13 11.8062 13.0742 11.5 13.208V12C11.5 11.7239 11.2761 11.5 11 11.5H9C8.72386 11.5 8.5 11.7239 8.5 12V15.5C8.5 16.3284 7.82843 17 7 17H4.5C3.67157 17 3 16.3284 3 15.5V8.44587C3 8.0202 3.18086 7.61457 3.4975 7.33008L8.9975 2.38854Z" />
        <path d="M16 9.26756C16.5978 9.61337 17 10.2597 17 11C17 11.7403 16.5978 12.3866 16 12.7324C15.7058 12.9026 15.3643 13 15 13C13.8954 13 13 12.1046 13 11C13 9.89543 13.8954 9 15 9C15.3643 9 15.7058 9.09739 16 9.26756Z" />
        <path d="M17.5 14C18.3284 14 19 14.6716 19 15.5C19 16.6161 18.541 17.5103 17.7879 18.1148C17.0466 18.7098 16.0531 19 15 19C13.9469 19 12.9534 18.7098 12.2121 18.1148C11.8358 17.8127 11.5329 17.4383 11.324 17C11.1793 16.6964 11.0797 16.3621 11.0321 16C11.0109 15.8388 11 15.672 11 15.5C11 15.0558 11.1931 14.6566 11.5 14.382C11.7654 14.1444 12.1158 14 12.5 14L17.5 14Z" />
      </g>
      <g className={cx(iconClassNames.filled, classes.filledPart)}>
        <path d="M8.9975 2.38854C9.56767 1.87626 10.4323 1.87627 11.0025 2.38854L16.5025 7.33008C16.8191 7.61457 17 8.0202 17 8.44587V8.76406C16.8468 8.62693 16.6796 8.5054 16.5007 8.40196C16.0584 8.14611 15.5448 8 15 8C13.3431 8 12 9.34315 12 11H11.502L11.5 11H8.5C8.22386 11 8 11.2239 8 11.5V15.5C8 16.3284 7.32843 17 6.5 17H4.5C3.67157 17 3 16.3284 3 15.5V8.44587C3 8.0202 3.18086 7.61457 3.4975 7.33008L8.9975 2.38854Z" />
        <path d="M12 14.0854C12.1564 14.0301 12.3247 14 12.5 14L17.5 14C18.3284 14 19 14.6716 19 15.5C19 16.6161 18.541 17.5103 17.7879 18.1148C17.0466 18.7098 16.0531 19 15 19C13.9469 19 12.9534 18.7098 12.2121 18.1148C11.8358 17.8127 11.5329 17.4383 11.324 17C11.1793 16.6964 11.0797 16.3621 11.0321 16C11.0109 15.8388 11 15.672 11 15.5C11 14.8469 11.4174 14.2913 12 14.0854Z" />
        <path d="M17 11C17 10.2597 16.5978 9.61337 16 9.26756C15.7058 9.09739 15.3643 9 15 9C13.8954 9 13 9.89543 13 11C13 12.1046 13.8954 13 15 13C15.3643 13 15.7058 12.9026 16 12.7324C16.5978 12.3866 17 11.7403 17 11Z" />
      </g>
    </svg>
  ),
  displayName: 'TenantPersonalIcon',
});
